import api from '@/api'
import Vue from 'vue'

const state = {}

const getters = {
	getNombreEmergenciasByDocumentoIdBySemana: () => async (data) => {
		try {
			const response = await api.documentoEmergenciasGimu.getNombreEmergenciasByDocumentoIdBySemana(data)
			if (response.status === 200) {
				return response.data.body
			}
		} catch (error) {
			Vue.notify({
				title: 'Error al consultar las emergencias',
				text: 'No se han obtenido las emergencias del informe semanal',
				type: 'error',
			})
		}
	}
}

const mutations = {}

const actions = {}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module