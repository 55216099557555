<template>
  <v-card flat class="ml-1 layer-last-item">
    <v-card-title v-if="showTitle">
      <div class="layerItem" justify="center">
        <v-checkbox v-model="visible" />
        <span @click="visible = !visible" class="layer-title">{{
          item.title
        }}</span>
        <div class="end-actions">
          <div class="layer-expand-icon">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="showContent(content, item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <span v-if="content">Ocultar configuración</span>
              <span v-else>Configuración transparencia</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-card-title>
    <v-card-text v-if="content || onlyPadre">
      <div>
        <v-slider
          v-if="opacity > 0"
          v-model="opacity"
          title="Opacidad"
          min="10"
          max="100"
          step="10"
          hide-details
          style="width: 80%; margin: auto"
        />
      </div>
      <div class="legend">
        <div
          v-for="(legendItem, index) in legendItems"
          :key="index"
          class="legend-item"
        >
          <LayerItemLegendIcon
            v-if="legendItem.colorSymbol"
            :legendIcon="legendItem.colorSymbol"
          />
          <img v-if="legendItem.legendURL" :src="legendItem.legendURL" />
          <img
            v-if="legendItem.base64Symbol"
            class="icon"
            :src="`data:image/png;base64,${legendItem.base64Symbol}`"
          />
          <div v-if="!legendItem.legendURL" class="label">
            <span>{{ legendItem.label || item.title }}</span>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import constants from '@/helpers/constants'
  import { mapActions } from 'vuex'
  import * as ArcGIS from '../../../helpers/ArcGIS'
  import LayerItemLegendIcon from './LayerItemLegendIcon'

  export default {
    props: {
      showTitle: Boolean,
      onlyPadre: Boolean,
      item: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },

    components: {
      LayerItemLegendIcon,
    },

    data: () => ({
      visible: false,
      opacity: null,
      content: false,
      capasActivas: false,
      showLegendFooter: false,
      legendItems: [],
    }),

    watch: {
      visible() {
        const layer = JSON.parse(JSON.stringify(this.item))
        layer.visible = this.visible

        // Si es la capa de emergencias (dev, pre o pro), cerrar contextMenu
        if (layer.title === constants.EMERGENCIA_LAYER_TITLE) {
          // Cerrar contextMenu
          this.$eventHub.$emit('showPopup', false)
          this.$eventHub.$emit('showContextMenu', false)
        }
        this.updateLayersPrueba(layer)
        this.comprobarCapaPadre()
        ArcGIS.changeVisibilityWebMap(this.item.id, this.visible)

        // Aviso al componente padre (layerList) que recargue la lista por si alguna capa ha cambiado de estado
        this.$eventHub.$emit('updateLayerList', true)
      },
      opacity() {
        /* this.setOpacityLayer({
        id: this.item.id,
        opacity: this.opacity / 100
      }) */

        // Cambio la opacidad de la capa actual
        const data = JSON.parse(JSON.stringify(this.item))
        data.opacity = this.opacity / 100

        // Actualizo la capa en la store
        this.updateLayersPrueba(data)
        ArcGIS.changeOpacityWebMap(this.item.id, this.opacity / 100)
      },
    },

    methods: {
      ...mapActions('map', ['setVisibilityLayerPrueba', 'setOpacityLayer']),
      ...mapActions('map', ['updateLayersPrueba']),

      showContent(show, item) {
        if (item) {
          // console.log('SHOW CONTENT: ', item)
          const layer = ArcGIS.getLayerById(item.id)
          // console.log('SHOW CONTENT LAYER: ', layer)
          const legendSymbols = ArcGIS.getLayerSymbols(layer)
          // console.log('LAYER SYMBOLS', legendSymbols)
          this.legendItems = legendSymbols.map((symbol) => {
            return {
              label: symbol.label,
              base64Symbol: symbol.base64Symbol?.source?.imageData || undefined,
              colorSymbol: symbol.colorSymbol,
              legendURL: symbol.legendURL,
            }
          })
        }
        if (!show) {
          this.content = true
        } else {
          this.content = false
        }
      },

      comprobarCapaPadre() {
        const nivel1 = structuredClone(this.$store.getters['map/layersPrueba'])
        for (let i = 0; i < nivel1.length; i++) {
          // NIVEL 1 (I)
          // Comprobar si tiene segundo nivel
          const nivel2 = nivel1[i].subLayers
          if (nivel2) {
            // Reccorremos el segundo nivel (Padre)
            for (let j = 0; j < nivel2.length; j++) {
              // NIVEL 2 (J)
              const nivel3 = nivel2[j].layers
              // Comprobar si tiene tercer nivel
              if (nivel3) {
                // Recorrer el tercel nivel (Hijo)
                for (let k = 0; k < nivel3.length; k++) {
                  // NIVEL 3 (K)
                  // Comprobar id capa clickada
                  const capaNivel3 = nivel3[k]
                  if (capaNivel3.id === this.item.id) {
                    // localizo el nivel donde se encuentra esta capa

                    // Localizada la capa hija, si estoy activando, paso el padre a visible
                    if (this.visible === true) {
                      // Mostrar icono de desactivar todas las capas en la lista de capas
                      this.capasActivas = true
                      // this.$eventHub.$emit('showDesactivarCapas', this.capasActivas)
                      // Visibilidad capa padre
                      const data = JSON.parse(JSON.stringify(nivel2[j]))
                      data.visible = true
                      this.updateLayersPrueba(data)

                      // Visibilidad capa abuelo
                      const data2 = JSON.parse(JSON.stringify(nivel1[i]))
                      data2.visible = true
                      this.updateLayersPrueba(data2)
                    } else {
                      // Recorro las capas (nivel 3) para saber si hay alguna mas visible, sino la pongo a false
                      let comprueba = false
                      let comprueba2 = false
                      for (const capaHermana of nivel2[j].layers) {
                        // console.log('capaHermana', capaHermana.visible)
                        if (
                          capaHermana.visible ===
                          true /*&& capaHermana.id !== capaNivel3.id*/
                        ) {
                          comprueba = true
                          this.capasActivas = true
                        }
                      }
                      // Las capas de nivel 3, del nivel 2 al que pertenecen, son false, nivel 2 es false
                      if (!comprueba) {
                        nivel1[i].subLayers[j].visible = false
                      }
                      // Recorro las capas de nivel 2
                      for (const capaHermana of nivel1[i].subLayers) {
                        if (capaHermana.visible === true) {
                          comprueba2 = true
                        }
                      }
                      this.capasActivas = comprueba2

                      // Visibilidad padre
                      const data = JSON.parse(JSON.stringify(nivel2[j]))
                      data.visible = comprueba

                      this.updateLayersPrueba(data)

                      // Visbibilidad abuelo
                      const data2 = JSON.parse(JSON.stringify(nivel1[i]))
                      data2.visible = comprueba2

                      this.updateLayersPrueba(data2)
                    }
                  } else {
                    if (
                      capaNivel3.visible &&
                      capaNivel3.id !== 'ContornoAnillos'
                    ) {
                      this.capasActivas = true
                    } // else if (!capanivel3.visible && capaNivel3.id !== "ContornoAnillos") {
                    //   this.capasActivas = false
                    // }
                  }

                  // Comprobar si tiene cuarto nivel
                  const nivel4 = nivel3[k].layers
                  if (nivel4) {
                    for (let l = 0; l < nivel4.length; l++) {
                      // NIVEL 4
                      const capaNivel4 = nivel4[l]
                      // Comprobar si es la capa seleccionada
                      if (capaNivel4.id === this.item.id) {
                        // Localizo el nivel donde se encuentra esta capa
                        // Localizada la capa de nivel 4, si estoy activando, paso el nivel 3, nivel 2 y nivel 1, a visible
                        if (this.visible === true) {
                          // Si la leyenda esta activa y la capa es de tipo vector-tileLayer, mostrar la leyenda del footer
                          if (capaNivel4.type === 'vector-tileLayer') {
                            this.showLegendFooter = true
                          }

                          // Mostrar icono de desactivar todas las capas en la lista de capas
                          this.capasActivas = true
                          // this.$eventHub.$emit('showDesactivarCapas', this.capasActivas)
                          // Visibilidad capa nivel 4
                          const dataNivel4 = JSON.parse(
                            JSON.stringify(capaNivel4),
                          )
                          dataNivel4.visible = true
                          this.updateLayersPrueba(dataNivel4)

                          // Visibilidad capa nivel 3
                          const visibilidadNivel3 = JSON.parse(
                            JSON.stringify(nivel3[k]),
                          )
                          visibilidadNivel3.visible = true
                          this.updateLayersPrueba(visibilidadNivel3)

                          // Visibilidad capa nivel 2
                          const visibilidadNivel2 = JSON.parse(
                            JSON.stringify(nivel2[j]),
                          )
                          visibilidadNivel2.visible = true
                          this.updateLayersPrueba(visibilidadNivel2)

                          // Visibilidad capa nivel 1
                          const visibilidadNivel1 = JSON.parse(
                            JSON.stringify(nivel1[i]),
                          )
                          visibilidadNivel1.visible = true
                          this.updateLayersPrueba(visibilidadNivel1)
                        } else {
                          // Recorro las capas hermanas, para saber si hay alguna mas visible, si no, la pongo a false
                          let comprueba = false
                          for (const capaHermana of nivel4) {
                            if (capaHermana.visible === true) {
                              comprueba = true
                              this.capasActivas = true

                              // Comprobar leyenda del footer
                              if (capaHermana.type === 'vector-tileLayer') {
                                this.showLegendFooter = true
                              }
                            }
                          }

                          // console.log('Hermanas nivel 4: ', comprueba)

                          this.capasActivas = comprueba

                          // Comprobar nivel 1, nivel 2, nivel 3
                          let compruebaNivel2 = false
                          // console.log('Valor de comprueba en el nivel 4: ', comprueba)

                          // Visibilidad capa nivel 3
                          const visibilidadNivel3 = JSON.parse(
                            JSON.stringify(nivel3[k]),
                          )
                          visibilidadNivel3.visible = comprueba
                          // Actualizacion local
                          nivel3[k].visible = comprueba
                          this.updateLayersPrueba(visibilidadNivel3)

                          for (let m = 0; m < nivel1.length; m++) {
                            // NIVEL 1
                            const activasNivel2 = nivel1[m].subLayers
                            if (activasNivel2) {
                              for (let n = 0; n < activasNivel2.length; n++) {
                                // NIVEL 2
                                const activasNivel3 = activasNivel2[n].layers
                                if (activasNivel3) {
                                  let checkNivel3 = false
                                  let checkNivel2 = false
                                  for (
                                    let o = 0;
                                    o < activasNivel3.length;
                                    o++
                                  ) {
                                    // NIVEL 3
                                    // Capas activas nivel 3
                                    if (activasNivel3[o].visible === true) {
                                      // console.log('Hay capas de nivel 3 activas: ', activasNivel3[o].title)
                                      checkNivel3 = true

                                      // Comprobar leyenda. Si tiene activas nivel 4 de tipo vector-tileLayer
                                      for (const capasNivel4 of activasNivel3[o]
                                        .layers) {
                                        if (
                                          capasNivel4.visible === true &&
                                          capasNivel4.type ===
                                            'vector-tileLayer'
                                        ) {
                                          this.showLegendFooter = true
                                        }
                                      }
                                    }
                                  }
                                  // Capas activas nivel 2
                                  if (
                                    activasNivel2[n].visible === true &&
                                    !checkNivel3 &&
                                    activasNivel2[n].id !== 'ContornoAnillos'
                                  ) {
                                    // console.log('Hay capas del nivel 2 activas: ', activasNivel2[n].title, 'type: ', activasNivel2[n].type)
                                    // console.log('Comprobacion nivel 3: ', checkNivel3)
                                    // compruebaNivel2 = true
                                    const visibilidadNivel2 = JSON.parse(
                                      JSON.stringify(nivel2[j]),
                                    )
                                    visibilidadNivel2.visible = checkNivel3 // Hijas nivel 3 false, padre nivel 2 false
                                    // Actualizar variable en el bucle
                                    nivel2[j].visible = checkNivel3
                                    activasNivel2[n].visible = checkNivel3
                                    this.updateLayersPrueba(visibilidadNivel2)
                                  }

                                  // Comprobar si hay mas capas de nivel 2, activas
                                  for (
                                    let p = 0;
                                    p < activasNivel2.length;
                                    p++
                                  ) {
                                    if (
                                      activasNivel2[p].visible === true &&
                                      activasNivel2[p].id !== 'ContornoAnillos'
                                    ) {
                                      // console.log('Quedan capas nivel 2, activas: ', activasNivel2[p].title)
                                      checkNivel2 = true
                                    }
                                  }
                                  // if (nivel2[j].visible === true) {
                                  //   checkNivel2 = true
                                  // }
                                  compruebaNivel2 = checkNivel2
                                } else {
                                  // console.log('Nivel 3 no tiene layers')
                                }
                              }
                              // Capas activas nivel 1
                              if (
                                nivel1[m].visible === true &&
                                !compruebaNivel2 &&
                                nivel1[m].id !== 'operationalLayer'
                              ) {
                                // console.log('Hay capas de nivel 1 activas: ', nivel1[m].title)
                                // console.log('Comprobacion nivel 2: ', compruebaNivel2)
                                const visibilidadNivel1 = JSON.parse(
                                  JSON.stringify(nivel1[m]),
                                )
                                visibilidadNivel1.visible = compruebaNivel2 // Hijas nivel 2 false, padre nivel 1, false
                                // Actualizar variable en el bucle
                                nivel1[i].visible = compruebaNivel2
                                // console.log('Nivel 1: ', nivel1[i].title, ', visibile: ', nivel1[i].visible)
                                this.updateLayersPrueba(nivel1[i])
                                // compruebaNivel1 = true
                              }
                            }
                          }
                        }
                      } else {
                        // Comprobar el resto de hijos

                        if (
                          capaNivel4.visible === true &&
                          capaNivel4.id !== 'ContornoAnillos'
                        ) {
                          this.capasActivas = true
                        }
                      }
                    }
                  }
                }
              } else {
                // Localizar la capa padre
                if (nivel2[j].id === this.item.id) {
                  // Si estoy activando un padre, pasar el abuelo a visible
                  if (this.visible === true) {
                    if (nivel2[j].id !== 'ContornoAnillos') {
                      // Mostrar icono de desactivar todas las capas en la lista de capas
                      this.capasActivas = true
                    }

                    // Visibilidad padre
                    const data = JSON.parse(JSON.stringify(nivel2[j]))
                    data.visible = true
                    this.updateLayersPrueba(data)

                    // Visibilidad abuelo
                    const data2 = JSON.parse(JSON.stringify(nivel1[i]))
                    data2.visible = true
                    this.updateLayersPrueba(data2)
                  } else {
                    // Recorrer las capas para saber si hay alguna mas visible, sino, ponemos la capa abuelo a false
                    let comprueba = false
                    for (const capaHermana of nivel1[i].subLayers) {
                      if (capaHermana.visible === true) {
                        comprueba = true
                      }
                    }

                    this.capasActivas = comprueba

                    // Visibilidad padre
                    const data = JSON.parse(JSON.stringify(nivel2[j]))
                    data.visible = false
                    this.updateLayersPrueba(data)

                    // Visibilidad abuelo
                    const data2 = JSON.parse(JSON.stringify(nivel1[i]))
                    data2.visible = comprueba
                    this.updateLayersPrueba(data2)
                  }
                }
              }
            }
          }
        }

        // Mostrar leyenda al activar una capa de riesgo, peligro, vulnerabilidad
        if (this.showLegendFooter) {
          // Activar leyenda footer en Map.vue
          this.$eventHub.$emit('tileLayerActivas', this.showLegendFooter)
          this.showLegendFooter = false
        } else {
          // Desactivar leyenda footer en Map.vue
          this.$eventHub.$emit('tileLayerActivas', this.showLegendFooter)
          this.showLegendFooter = false
        }

        // ArcGIS.changeVisibilityWebMap(this.item.id, this.visible)
        /* let dataItem = this.item
      dataItem.visible = this.visible
      this.updateLayersPrueba (dataItem)*/
        this.$eventHub.$emit('updateLayerList', true)
      },
    },

    created() {
      this.visible = this.item.visible

      if (this.item.opacity) {
        this.opacity = this.item.opacity * 100
      }
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    width: 100%;
    padding: 0px 0px 0px 10px;
  }

  .layer-last-item {
    flex-direction: column;
    padding: 0 !important;
    .legend {
      padding-bottom: 0.5rem;
      .legend-item {
        display: flex;
        font-size: 9pt;
        width: 90%;
        margin: auto;
        overflow: auto;
        gap: 0.3rem;
        align-items: center;
        .color-icon {
          width: 20px;
          height: 20px;
          border: 1px solid black;
        }
        .icon {
          max-height: 25px;
        }
      }
    }

    .layerItem {
      width: 100%;
      font-size: 1rem;
      border-radius: 10px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      line-height: 1rem;

      .layer-title {
        width: 100%;
        cursor: pointer;
        user-select: none;
      }

      .v-input--selection-controls {
        margin: 0;
        margin-top: 0;
        padding-top: 0;
        ::v-deep .v-messages {
          min-height: 0;
        }
        ::v-deep .v-input--selection-controls__ripple {
          height: auto;
          margin: 0;
        }
      }
      ::v-deep .v-input__slot {
        margin-bottom: 0;
      }
      .end-actions {
        display: flex;
        flex-direction: row;
        ::v-deep .v-btn--icon.v-size--default {
          height: auto;
          width: auto;
        }
      }
    }
  }
</style>
