import request from './request'

export class SyncDBRequest {
  static async syncDB(syncOrders) {
    console.log('syncOrders', syncOrders)
    try {
      return (await request.post('syncDB', syncOrders)).data.body
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.SyncDB
            .syncDB,
          type: 'error',
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error',
        })
      }
    }
  }
}