export const baseUrl = process.env.VUE_APP_API_URL
export const isSandBox = process.env.VUE_APP_SANDBOX_ACTIVE === 'yes'
export const baseMap = {
  id: process.env.VUE_APP_ARCGIS_MAP_ID,
  mobile: process.env.VUE_APP_ARCGIS_MOBILE_MAP_ID,
  portal: 'https://www.arcgis.com',
}
export const credentialsArcGIS = {
  client_id: process.env.VUE_APP_ARCGIS_CLIENT_ID,
  client_secret: process.env.VUE_APP_ARCGIS_CLIENT_SECRET
}