<template>
  <v-dialog class="dialog-simulate-user" v-model="isOpen" width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Sincronizar entornos</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="dialog-content">
        <p>Seleccione las partes de la aplicación que desea sincronizar</p>
        <v-list-item-group multiple v-model="selectedOptions">
          <v-list-item
            v-for="option in importOptions"
            :key="option.value"
            :value="option"
            active-class="deep-purple--text text--accent-4"
          >
            <template #default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ option.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn
          :disabled="isSaving || selectedOptions.length < 1"
          :loading="isSaving"
          text
          color="green"
          @click="aceptar"
        >
          Sincronizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import api from '@/api'

  const SYNCDB_OPTIONS = {
    ALL: 'ALL',
    USERS: 'USERS',
    CONTACTS: 'CONTACTS',
    ENTITIES: 'ENTITIES',
    CARGOS: 'CARGOS',
    MEDIOS: 'MEDIOS',
    DELEGACIONES: 'DELEGACIONES',
    DEPARTAMENTOS: 'DEPARAMENTOS',
    GRUPOS_ACCION: 'GRUPOS_ACCION',
    POSIBLES_ESCENARIOS: 'POSIBLES_ESCENARIOS',
  }

  export default {
    expose: ['cancelar', 'open'],
    props: {
      show: Boolean,
    },

    components: {},

    data: () => ({
      isOpen: false,
      isSaving: false,
      importOptions: [
        {
          value: SYNCDB_OPTIONS.ALL,
          text: 'Tablas Base',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.USERS,
          text: 'Usuarios',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.CONTACTS,
          text: 'Contactos',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.ENTITIES,
          text: 'Entidades',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.CARGOS,
          text: 'Cargos',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.MEDIOS,
          text: 'Medios',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.DELEGACIONES,
          text: 'Delegaciones',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.DEPARTAMENTOS,
          text: 'Departamentos',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.GRUPOS_ACCION,
          text: 'Grupos de Acción',
          active: true,
        },
        {
          value: SYNCDB_OPTIONS.POSIBLES_ESCENARIOS,
          text: 'Posibles Escenarios',
          active: true,
        },
      ],
      selectedOptions: () => this.importOptions,
    }),

    methods: {
      open() {
        this.isOpen = true
      },
      cancelar() {
        this.isOpen = false
      },
      async aceptar() {
        this.$root.$confirmDialog
          .open(
            '¿Está seguro?',
            'Esta operacion puede eliminar datos existentes en el entorno de simulación',
          )
          .then(async (result) => {
            if (result) {
              try {
                this.isSaving = true
                this.$notify({
                  title: 'Iniciando sincronización',
                  text: 'Se estan sincronizando los datos de entorno seleccionados',
                })
                await api.syncDB.syncDB(
                  this.selectedOptions.map((option) => option.value),
                )
                this.$notify({
                  title: 'Sincronización completada',
                  text: 'Los datos de entorno seleccionados se han sincronizado correctamente',
                })
                this.isOpen = false
              } catch (error) {
                this.$notify({
                  title: 'Sincronización fallida',
                  text: 'Ha ocurrido un error al sincronizar los datos de entorno seleccionados',
                  type: 'error',
                })
                console.error(error)
              } finally {
                this.isSaving = false
              }
            }
          })
      },
    },

    computed: {},
  }
</script>

<style scoped lang="scss">
  .inputText {
    margin-top: -15px;
  }
  .usuarios {
    height: 20rem;
  }
  .v-card__text.dialog-content {
    margin: 0;
    padding: 0;
    p {
      padding-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 1.15em;
    }
  }
</style>
