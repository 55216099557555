<template>
  <div class="emergencias">
    <v-list>
      <v-list-item-group v-model="emergenciasSelected" multiple>
        <v-list-item
          v-for="emer in emergenciasFiltered"
          :key="emer.id"
          :value="emer.id"
        >
          <template #default="{ active }">
            <v-list-item-content>
              <v-list-item-title>{{ emer?.nombre }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox :input-value="active" />
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
  export default {
    props: {
      emergencia: Object,
      listaEmergenciasNoAsignadas: Array,
      isOpen: Boolean,
      filter: {
        type: String,
        optional: true,
        default: '',
      },
    },

    data: () => ({
      emergenciasSelected: [],
      orderEmergencias: null,
      emergenciasSelectedToDesassign: [],
    }),

    emits: ['emergenciasSelected', 'emergenciasSelectedToDesassign'],

    watch: {
      async emergenciasSelected(newValue) {
        await this.actualizarEmergenciasRelacionadas(newValue)
        this.$emit('emergenciasSelected', {
          addSelected: newValue,
          deleteSelected: this.emergenciasSelectedToDesassign,
        })
      },
    },

    computed: {
      emergenciasFiltered() {
        if (!this.filter) {
          this.actualizarSeleccionadas()
          return this.orderEmergencias
        } else {
          const result = this.orderEmergencias.filter((emergencia) =>
            emergencia.nombre.toLowerCase().includes(this.filter.toLowerCase()),
          )
          return result
        }
      },
    },

    methods: {
      async loadAndOrderEmergencias() {
        if (this.emergencia?.emergencias) {
          // Filtrar las que no estén en la lista de la propia emergencia
          const filtradas = await this.listaEmergenciasNoAsignadas.filter(
            (item) => !this.emergencia?.emergencias.includes(item),
          )

          // this.listaEmergenciasNoAsignadas = propiasEmergencia
          // Ordenar las emergencias con las emergencias de la emergencia padre
          this.orderEmergencias = [...filtradas]
          this.orderEmergencias.sort((a, b) => {
            const aNombre = a?.nombre
            const bNombre = b?.nombre

            if (aNombre && bNombre) {
              if (aNombre !== bNombre) {
                return aNombre < bNombre ? -1 : 1
              }
            }
          })
          // Marcar las emergencias ya asociadas de la emergencia padre que editamos
          for (const emer of this.emergencia.emergencias) {
            this.emergenciasSelected.push(emer.id)
          }
        }
      },

      async actualizarEmergenciasRelacionadas(emergenciasNew) {
        // Actualizar emergencias previos desmarcadas
        this.emergenciasSelectedToDesassign = await this.emergencia?.emergencias
          .filter((item) => !emergenciasNew.includes(item.id))
          .map((item) => item.id)
      },

      actualizarSeleccionadas() {
        // Marcar las emergencias ya asociadas de la emergencia padre que editamos
        for (const emer of this.emergencia.emergencias) {
          this.emergenciasSelected.push(emer.id)
        }
      },
    },

    async mounted() {
      // Insertar emergencias asociadas de la emergencia a la lista de emergencias
      await this.loadAndOrderEmergencias()
    },
  }
</script>

<style></style>
