import Vue from 'vue'
import Vuex from 'vuex'
// import { createLogger } from 'vuex/dist/logger'

import notifications from './plugins/notifications'

import app from './modules/app'
import auth from './modules/auth'
import map from './modules/map'
import shareMap from './modules/shareMap'
import incendio from './modules/incendio'
import parametro from './modules/parametro'
import usuario from './modules/usuario'
import rol from './modules/rol'
import organizacion from './modules/organizacion'
import contacto from './modules/contacto'
import medio from './modules/medio'
import entidad from './modules/entidad'
import distrito from './modules/distrito'
import grupoAccion from './modules/grupoAccion'
import periodicidad from './modules/periodicidad'
import tipoActualizacion from './modules/tipoActualizacion'
import tipoEntidad from './modules/tipoEntidad'
import tipoMedio from './modules/tipoMedio'
import delegacion from './modules/delegacion'
import nivelContacto from './modules/nivelContacto'
import unidadMedio from './modules/unidadMedio'
import sectorMedio from './modules/sectorMedio'
import actividadMedio from './modules/actividadMedio'
import categoriaDocumento from './modules/categoriaDocumento'
import documento from './modules/documento'
import simulacion from './modules/simulacion'
import emergencia from './modules/emergencia'
import tipoAccion from './modules/tipoAccion'
import accionSituacionTipoEmergencia from './modules/accionSituacionTipoEmergencia'
import chat from './modules/chat'
import accionSituacionEmergencia from './modules/accionSituacionEmergencia'
import cargo from './modules/cargo'
import tipoEventoBitacora from './modules/tipoEventoBitacora'
import bitacora from './modules/bitacora'
import documentoEmergenciasGimu from './modules/documentoEmergenciasGimu'

import vuexPersistedstate from 'vuex-persistedstate'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

const persistedState = vuexPersistedstate({
  key: 'pemam_app_state',
  reducer: (state) => ({
    auth: state.auth,
    usuario: state.usuario,
  }),
})

export default new Vuex.Store({
  modules: {
    app,
    auth,
    map,
    shareMap,
    rol,
    organizacion,
    contacto,
    incendio,
    medio,
    entidad,
    distrito,
    grupoAccion,
    periodicidad,
    tipoActualizacion,
    tipoEntidad,
    tipoMedio,
    delegacion,
    nivelContacto,
    unidadMedio,
    sectorMedio,
    actividadMedio,
    categoriaDocumento,
    documento,
    parametro,
    usuario,
    simulacion,
    emergencia,
    tipoAccion,
    accionSituacionTipoEmergencia,
    accionSituacionEmergencia,
    chat,
    cargo,
    tipoEventoBitacora,
    bitacora,
    documentoEmergenciasGimu
  },
  plugins: [persistedState, notifications],
  strict: debug,
})
