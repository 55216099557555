<template>
  <div class="content">
    <DialogEmergenciasAsociadasVue
      ref="dialogEmergenciasAsociadas"
      :emergencia="emergencia"
      @resultadoRelacionEmergenciasGimu="resultadoRelacionEmergenciasGimu"
    />

    <!-- TODO: permisos de la tabla -->
    <v-data-table
      :class="{
        table: !$vuetify.breakpoint.mobile,
        tableMobile: $vuetify.breakpoint.mobile,
      }"
      :headers="headers"
      :items="emergencia?.emergencias ? emergencia?.emergencias : []"
      height="100%"
      loading-text="Cargando datos... por favor, espere"
      item-key="id"
    >
      <!-- NO DATA -->
      <template #no-data>
        <p>Datos no disponibles.</p>
      </template>

      <!-- ACTIVA -->
      <template #[`item.activa`]="{ item }">
        <span>{{ formatBoolean(item?.activa) }}</span>
      </template>

      <!-- ACCIONES -->
      <template #[`item.ACCIONES`]="{ item }">
        <!-- IR A EMERGENCIA -->
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              v-show="comprobarPermisos(['VER_EMERGENCIAS', 'MOD_EMERGENCIAS'])"
              @click="cargarEmergenciaAsociada(item)"
            >
              <v-icon color="teal">mdi-map-marker</v-icon>
            </v-btn>
          </template>
          <span>Ver emergencia</span>
        </v-tooltip>
      </template>

      <!-- ACCIONES CABECERA -->
      <template #[`header.ACCIONES`]>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              v-show="comprobarPermisos(['MOD_EMERGENCIAS'])"
              @click="() => $refs.dialogEmergenciasAsociadas.open()"
              :disabled="!emergencia || !emergencia?.emergencias"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Emergencias relacionadas</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import * as ArcGIS from '@/helpers/ArcGIS'
  import DialogEmergenciasAsociadasVue from './DialogEmergenciasAsociadas.vue'

  export default {
    components: {
      DialogEmergenciasAsociadasVue,
    },

    props: {
      emergencia: Object,
    },

    data: () => ({}),

    computed: {
      headers() {
        return [
          {
            text: 'Activa',
            value: 'activa',
            align: 'left',
            width: '85px',
            sortable: true,
          },
          {
            text: 'Emergencia',
            value: 'nombre',
            align: 'left',
            width: '290px',
            sortable: true,
          },
          {
            text: 'Situación Operativa',
            value: 'SituacionOperativa.nombre',
            align: 'left',
            width: '260px',
            sortable: true,
          },
          {
            text: 'Acciones',
            value: 'ACCIONES',
            align: 'center',
            width: '25%',
            sortable: false,
          },
        ]
      },
    },

    methods: {
      formatBoolean(activa) {
        return activa ? 'Sí' : 'No'
      },

      cargarEmergenciaAsociada(emergencia) {
        this.$store.commit('emergencia/setEmergenciaSelected', emergencia)
        if (emergencia) {
          ArcGIS.setCenterMap({
            target: [emergencia.longitude, emergencia.latitude],
            zoom: 15,
          })
        }
      },

      async resultadoRelacionEmergenciasGimu() {
        // Recargar emergencias asociadas de la emergencia padre
        await this.$store.dispatch('emergencia/updateEmergenciaSelectedData')
      },

      comprobarPermisos(listaRolesPermisos) {
        return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
      },
    },

    mounted() {},
  }
</script>
<style scoped lang="scss">
  .table {
    width: 100vw;
    max-height: calc(100vh - 280px);
  }
  .tableMobile {
    width: 100vw;
    max-height: calc(100vh - 360px);
  }
  .v-data-table {
    overflow: auto;
  }
  .v-data-table >>> .v-data-table__wrapper {
    /* Header fijo */
    overflow: unset;
  }

  .content {
    padding: 12px;
    .datos {
      width: 100%;
      display: flex;
      gap: 6px;
      flex-direction: column;
      margin-bottom: 9px;
      span {
        line-height: 19px;
        .label-title {
          font-weight: bold;
        }
      }
    }
  }
</style>
