<template>
  <v-dialog v-model="isOpen" scrollable width="500px" persistent>
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title> Relacionar emergencias </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row class="mt-0">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Buscar"
                  placeholder="Buscar"
                  v-model="emergenciaFilter"
                  counter
                  maxlength="250"
                  dense
                  :disabled="loading"
                />
              </v-col>
            </v-row>
          </v-container>
          <EmergenciasSelectListVue
            v-if="emergenciasSinRelacion"
            :emergencia="emergencia"
            :listaEmergenciasNoAsignadas="emergenciasSinRelacion"
            :filter="emergenciaFilter"
            @emergenciasSelected="emergenciasSelected = $event"
            @emergenciasSelectedToDesassign="
              emergenciasSelectedToDesassign = $event
            "
          />
          <v-btn v-else text color="primaryLight" :loading="loading" disabled>
            Cargando datos de emergencias
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" :disabled="loading" @click="cancelar">
          Cancelar
        </v-btn>
        <v-btn
          :disabled="!emergenciasSelected || loading"
          :loading="loading"
          text
          color="green"
          @click="aceptar"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { EmergenciaRequest } from '@/api/emergencia'
  import EmergenciasSelectListVue from './EmergenciasSelectList.vue'
  import { mapActions } from 'vuex'
  export default {
    components: {
      EmergenciasSelectListVue,
    },

    props: {
      emergencia: Object,
    },

    data: () => ({
      isOpen: false,
      emergenciaFilter: null,
      emergenciasSelected: [],
      emergenciasSinRelacion: null,
      emergenciasSelectedToDesassign: [],
      resultadoRelacionEmergenciasGimu: false,
      loading: false,
    }),

    computed: {},

    methods: {
      ...mapActions('emergencia', [
        'updateEmergenciaSelectedEmergenciasRelacionadas',
      ]),
      async open() {
        this.isOpen = true

        // Cargar lista de emergencias que se pueden asignar
        this.loading = true
        this.emergenciasSinRelacion =
          await EmergenciaRequest.getEmergenciasSinAsignarAtipoReunionGimu()
        this.loading = false
      },

      cancelar() {
        // this.emergencia = []
        this.isOpen = false
        // this.emergencias = []
        this.emergenciasSelected = []
        this.emergenciaFilter = null
        this.emergenciasSinRelacion = null
        this.emergenciasSelectedToDesassign = []
        //TODO: funcionalidad de reset de los datos
      },

      async aceptar() {
        this.loading = true
        const actualizarEmergencias =
          await this.prepararActualizacionEmergencias(
            this.emergencia,
            this.emergenciasSelected.addSelected,
            this.emergenciasSelected.deleteSelected,
          )

        // Enviar al backend
        this.resultadoRelacionEmergenciasGimu = await this.$store.dispatch(
          'emergencia/updateEmergenciaSelectedEmergenciasRelacionadas',
          actualizarEmergencias,
        )
        this.loading = false
        this.isOpen = false
      },

      async prepararActualizacionEmergencias(
        emergencia,
        emergenciasNew,
        emergenciasOld,
      ) {
        return {
          emergenciaPadre: emergencia.id,
          asignarEmergencias: emergenciasNew,
          desasignarEmergencias: emergenciasOld,
        }
      },
    },

    emits: ['resultadoRelacionEmergenciasGimu'],

    watch: {
      resultadoRelacionEmergenciasGimu(value) {
        this.$emit('resultadoRelacionEmergenciasGimu', value)
      },
    },

    mounted() {},
  }
</script>

<style></style>
