<template>
  <div class="content">
    <div class="bitacora-container">
      <RegistroBitacoraVue
        v-for="registro in registros"
        :key="registro.id"
        :registro="registro"
      />
    </div>
  </div>
</template>

<script>
  import RegistroBitacoraVue from './RegistroBitacora.vue'
  import { mapActions } from 'vuex'

  export default {
    components: {
      RegistroBitacoraVue,
    },

    props: {
      reloadBitacora: Boolean,
      emergencia: null,
    },

    data: () => ({}),

    computed: {
      registros() {
        return this.$store.state.bitacora.registrosBitacoraEmergencia
      },
    },

    watch: {
      async reloadBitacora(newValue) {
        newValue && (await this.updateRegistrosBitacora(this.emergencia?.id))
      },
    },

    methods: {
      ...mapActions('bitacora', ['getRegistrosBitacoraByEmergencia']),
      async updateRegistrosBitacora(emergenciaId) {
        await this.getRegistrosBitacoraByEmergencia(emergenciaId)
        await this.registros
      },
    },

    async mounted() {
      await this.updateRegistrosBitacora(this.emergencia.id)
    },
  }
</script>

<style></style>
