<template>
  <v-tooltip right>
    <template #activator="{}">
      <v-card
        :color="colorEmergencia"
        elevation="6"
        dark
        class="emergencia"
        @click="selecionarEmergencia(emergencia)"
      >
        <div class="header">
          <span>{{ emergencia.nombre }}</span>
          <img src="@/assets/emergencias/abrir_emergencia.svg" />
          <!--<v-icon color="white">mdi-eye-off</v-icon>-->
        </div>
        <span class="subtitle" v-if="emergencia.SituacionOperativa">{{
          emergencia.SituacionOperativa.nombre
        }}</span>
        <div class="botom-line">
          <span>{{ emergencia.activa ? 'Activa' : 'Inactiva' }}</span>
          <span class="date">{{ emergencia.fechaCreacion | formatDate }}</span>
        </div>
      </v-card>
    </template>
  </v-tooltip>
</template>

<script>
  import constants from '@/helpers/constants'

  export default {
    props: {
      emergencia: {
        type: Object,
      },
    },

    methods: {
      selecionarEmergencia(emergencia) {
        this.$store.commit('emergencia/setEmergenciaSelected', emergencia)
        this.$emit('selectEmergencia', emergencia)
      },
    },

    computed: {
      colorEmergencia() {
        if (this.emergencia.activa) {
          switch (this.emergencia.SituacionOperativa?.codigo ?? 0) {
            case constants.SITUACION_OPERATIVA_CODIGO.SOP0:
              return '#C2CB00'
            case constants.SITUACION_OPERATIVA_CODIGO.SOP1:
              return '#ED1C24'
            case constants.SITUACION_OPERATIVA_CODIGO.SOP2:
              return '#760000'
            case constants.SITUACION_OPERATIVA_CODIGO.RECUPERACION:
              return '#008ECC'
            case constants.SITUACION_OPERATIVA_CODIGO.SOP3:
              return '#5F18BC'
            case constants.SITUACION_OPERATIVA_CODIGO.NORMALIDAD:
              return '#008001'
            default:
              return ''
          }
        } else {
          return '#969696'
        }
      },
      colorEmergencia2() {
        return '#969696'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .emergencia {
    user-select: none;
    padding: 8px;
    margin: 8px;
    .header {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .subtitle {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
    .botom-line {
      display: flex;
      justify-content: space-between;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      .date {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
</style>
