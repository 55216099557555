<template>
  <v-dialog v-model="show" scrollable persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLigth" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row class="mt-1">
              <v-col>
                <!-- Nombre entidad -->
                <v-text-field
                  label="Entidad*"
                  v-model="entidad_titular"
                  dense
                  :rules="[globalRules.required]"
                  maxlenght="50"
                  required
                />
              </v-col>
              <v-col>
                <!-- Tipo entidad -->
                <v-select
                  v-model="tipoEntidadSelected"
                  label="Tipo entidad*"
                  :items="tiposEntidad"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  :rules="[globalRules.required]"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- Grupo accion -->
                <v-select
                  v-model="grupoAccionSelected"
                  label="Grupo acción"
                  :items="gruposAccion"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  multiple
                  outlined
                  dense
                />
              </v-col>
              <v-col>
                <!-- Distrito -->
                <v-select
                  v-model="distritoSelected"
                  label="Distrito"
                  :items="distritos"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <!-- Domicilio -->
                <v-text-field
                  label="Dirección"
                  v-model="domicilio"
                  dense
                  maxlenght="50"
                />
              </v-col>
              <v-col>
                <!-- CP -->
                <v-text-field
                  label="Código postal"
                  v-model="cp"
                  v-mask="'#####'"
                  dense
                  maxlenght="5"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- Convenio -->
                <v-select
                  v-model="convenioSelected"
                  label="Convenio"
                  :items="opcionConvenio"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                />
              </v-col>

              <v-col>
                <!-- Tipo actualizacion -->
                <v-select
                  v-model="tipoActualizacionSelected"
                  label="Tipo actualizacion"
                  :items="tiposActualizacion"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- Periodicidad -->
                <v-select
                  v-model="periodicidadSelected"
                  label="Periodicidad"
                  :items="periodicidades"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                />
              </v-col>

              <v-col>
                <!-- Fecha recogida de informacion -->
                <v-menu
                  ref="fechaInit"
                  v-model="showFechaInit"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="fechaInitFormatted"
                      @click:clear="fechaInit = null"
                      label="Recogida de información"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                      hide-details
                    />
                  </template>
                  <v-date-picker
                    v-model="fechaInit"
                    @input="$refs.fechaInit.save(cerrarCalendario)"
                    locale="es-ES"
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" style="margin-top: -15px; margin-bottom: -20px">
                <!-- INCLUIR ENTIDAD EN CHAT POR DEFECTO -->
                <v-checkbox v-model="isChatDefault">
                  <template #label>
                    <div>
                      Incluir entidad en el chat general en el momento que se
                      declara una emergencia.
                    </div>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col
                cols="12"
                style="margin-top: -20px; margin-bottom: -30px"
                v-if="userIsAdmin"
              >
                <!-- INCLUIR ENTIDAD EN EL CHAT POR DEFECTO PARA EMERGENCIAS DE TIPO REUNIÓN GIMU -->
                <v-checkbox v-model="isChatDefaultReunionGimu">
                  <template #label>
                    <div>
                      Incluir entidad en el chat general para las emergencias de
                      tipo "Reunión GIMU".
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- Documentacion convenio -->
                <v-file-input
                  v-if="showDocumentInput"
                  v-model="documentInput"
                  small-chips
                  ref="inputFile"
                  label="Seleccione documento pdf"
                  :clearable="true"
                  accept=".pdf"
                  dense
                  :show-size="true"
                  :rules="fileMaxSize"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- Logo corporativo de la entidad -->
                <v-file-input
                  v-model="logoInput"
                  small-chips
                  ref="inputImage"
                  label="Imagen corporativa"
                  :clearable="true"
                  dense
                  :accept="['.jpg', '.jpeg', '.jpe', '.png']"
                  :show-size="true"
                  :rules="fileMaxSize"
                />
              </v-col>
              <v-col class="mt-1">
                <v-row>
                  <span>Formatos admitidos: jpg, jpeg, jpe, png.</span>
                </v-row>
                <v-row><span>Tamaño máximo: 3 MB</span></v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <!-- <v-btn text color="green" @click="aceptar">Aceptar</v-btn> -->
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      show: Boolean,
      isEdit: Boolean,
      editedEntidad: Object,
    },

    data: () => ({
      isValid: false,
      entidad: null,
      entidad_titular: null,
      domicilio: null,
      cp: null,
      tipoEntidadSelected: null,
      grupoAccionSelected: [],
      tipoActualizacionSelected: null,
      periodicidadSelected: null,
      distritoSelected: null,
      convenioSelected: null,
      showDocumentInput: false,
      documentInput: null,
      documentBase64: '',
      logoInput: null,
      logoBase64: '',
      showFechaInit: false,
      fechaInit: null,
      isChatDefault: null,
      isChatDefaultReunionGimu: null,
    }),

    watch: {
      async show() {
        if (this.show) {
          if (this.editedEntidad) {
            this.entidad = this.editedEntidad
            this.entidad_titular = this.editedEntidad.entidad_titular
            this.tipoEntidadSelected = this.getSelectedTipoEntidad(
              this.editedEntidad,
            )
            this.tipoActualizacionSelected =
              this.tiposActualizacion &&
              this.getSelectedTipoActualizacion(this.editedEntidad)
            this.periodicidadSelected =
              this.editedEntidad.Periodicidad &&
              this.getSelectedPeriodicidad(this.editedEntidad)
            this.grupoAccionSelected = this.editedEntidad.Grupo_accions
            this.distritoSelected =
              this.editedEntidad.id_distrito &&
              this.getSelectedDistrito(this.editedEntidad)
            this.domicilio = this.editedEntidad.domicilio
            this.cp = this.editedEntidad.cp
            this.isChatDefault = this.editedEntidad.is_chat_default
            this.isChatDefaultReunionGimu =
              this.editedEntidad.is_chat_default_reunion_gimu

            // Fecha recogida informacion
            if (this.editedEntidad.recogida_info) {
              const formatDate = new Date(
                new Date(this.editedEntidad.recogida_info) -
                  new Date().getTimezoneOffset() * 60000,
              )
                .toISOString()
                .substr(0, 10)
              this.fechaInit = formatDate
            }

            // Convenio (puede ser nulo)
            if (this.editedEntidad.convenio !== null) {
              this.convenioSelected = this.opcionConvenio.find(
                (x) => x.value === this.editedEntidad.convenio,
              )
            }

            // Cargar la documentacion del convenio (en caso de tenerla)
            if (this.editedEntidad.documentacion) {
              this.cargarDocumentacion(this.editedEntidad)
            }

            // Cargar logo de la entidad
            if (this.editedEntidad.logo) {
              this.logoInput = await this.cargarLogoCorporativo(
                this.editedEntidad,
              )
            }
          }
        }
      },

      documentInput() {
        // TODO: por el momento solo admite documentos pdf
        if (
          this.documentInput &&
          !this.tipoDeDocumentoAdmitido(this.documentInput)
        ) {
          this.documentInput = null
          this.$refs.inputFile.reset()
          this.notificarDocumentoNoAdmitido()
        }
      },

      logoInput(newValue) {
        if (newValue && !this.tipoDeImagenAdmitida(newValue)) {
          this.logoInput = null
          this.$refs.inputImage.reset()
          this.notificarImagenNoAdmitida()
        }
      },

      convenioSelected() {
        if (this.convenioSelected) {
          this.showDocumentInput = this.convenioSelected.value === true
        }
      },
    },

    computed: {
      accionDialog() {
        return this.editedEntidad ? 'Editar entidad' : 'Nueva entidad'
      },
      ...mapGetters('distrito', ['distritos']),
      ...mapGetters('tipoEntidad', ['tiposEntidad']),
      ...mapGetters('grupoAccion', ['gruposAccion']),
      ...mapGetters('periodicidad', ['periodicidades']),
      ...mapGetters('tipoActualizacion', ['tiposActualizacion']),
      ...mapGetters('usuario', ['currentUsuario']),
      ...mapGetters('entidad', ['getDocumentoByEntidad']),
      opcionConvenio() {
        return [
          {
            nombre: 'Sí',
            value: true,
          },
          {
            nombre: 'No',
            value: false,
          },
        ]
      },
      fechaInitFormatted() {
        const date = this.$date.parseDate(this.fechaInit, 'YYYY-MM-DD')
        return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
      },
      userIsAdmin() {
        return this.$store.getters['usuario/rolesUsuario'](['ADMIN'])
      },
    },

    methods: {
      async cargarDocumentacion(entidad) {
        const pdfBase64 = await this.$store.getters[
          'entidad/getDocumentoByEntidad'
        ](entidad.id_entidad)
        var buffer = new ArrayBuffer(pdfBase64.documento.length)
        var ba = new Uint8Array(buffer)
        for (var i = 0; i < pdfBase64.documento.length; i++) {
          ba[i] = pdfBase64.documento.charCodeAt(i)
        }
        var nombreDoc =
          typeof entidad.documentacion === 'object'
            ? entidad.documentacion.nombre
            : entidad.documentacion
        var base64ToPdf = new File([ba], nombreDoc, { type: 'application/pdf' })
        if (base64ToPdf) {
          this.documentInput = base64ToPdf
        }
      },

      async cargarLogoCorporativo(entidad) {
        const logo = entidad.logo.logo
        const buffer = new ArrayBuffer(logo.length)
        var ba = new Uint8Array(buffer)
        for (let i = 0; i < logo.length; i++) {
          ba[i] = logo.charCodeAt(i)
        }
        const nombreLogo =
          typeof entidad.logo === 'object' ? entidad.logo.nombre : entidad.logo
        const mimeType = await this.getImagenMimeType(entidad.logo.nombre)
        const base64ToImage = new File([ba], nombreLogo, { type: mimeType })
        if (base64ToImage) {
          return base64ToImage
        }
      },

      getImagenMimeType(nombreImagen) {
        let nombre = nombreImagen
        // Reverse del nombre de la imagen
        nombre = [...nombre].reverse().join('')

        /**
         * Obtener la extension de la imagen hasta el primer .
         *
         * De esta forma, evitamos que el nombre de la imagen contenga puntos y no
         * podamos obtener correctamente la extension
         */
        const corteExtension = nombre.split('.')[0]
        const reverseExtension = [...corteExtension].reverse().join('')

        switch (reverseExtension) {
          case 'jpg':
            return 'image/jpeg'
          case 'jpeg':
            return 'image/jpeg'
          case 'jpe':
            return 'image/jpeg'
          case 'png':
            return 'image/png'
        }
      },

      getImagenExtension(nombreImagen) {
        let nombre = nombreImagen
        // Reverse del nombre de la imagen
        nombre = [...nombre].reverse().join('')

        /**
         * Obtener la extension de la imagen hasta el primer .
         *
         * De esta forma, evitamos que el nombre de la imagen contenga puntos y no
         * podamos obtener correctamente la extension
         */
        const corteExtension = nombre.split('.')[0]
        const reverseExtension = [...corteExtension].reverse().join('')

        return '.' + reverseExtension
      },

      getSelectedDistrito(entidad) {
        return this.distritos.find(
          (distrito) => distrito.id_distrito === entidad.Distrito.id_distrito,
        )
      },

      getSelectedTipoEntidad(entidad) {
        return this.tiposEntidad.find(
          (tipo) =>
            tipo.id_tipo_entidad === entidad.Tipo_entidad.id_tipo_entidad,
        )
      },

      getSelectedTipoActualizacion(entidad) {
        return this.tiposActualizacion.find(
          (tipoActualizacion) =>
            tipoActualizacion.id_tipo_actualizacion ===
            entidad.Tipo_actualizacion?.id_tipo_actualizacion,
        )
      },

      getSelectedPeriodicidad(entidad) {
        return (this.periodicidadSelected = this.periodicidades.find(
          (x) => x.id_periodicidad === entidad.Periodicidad.id_periodicidad,
        ))
      },

      cancelar() {
        this.fechaInit = null
        this.convenioSelected = null
        this.showDocumentInput = false
        this.$refs.form.reset()
        this.$emit('cancelar')
      },

      montarEntidadGrupoAccion(entidad) {
        return this.grupoAccionSelected.map((grupo) => ({
          id_entidad_grupo_accion: this.$uuid.createUUID(),
          id_grupo_accion: grupo.id_grupo_accion,
          id_entidad: entidad.id_entidad,
          usuariostamp: this.currentUsuario.ID,
          borrado: false,
        }))
      },

      async aceptar() {
        const entidad = this.editedEntidad
          ? JSON.parse(JSON.stringify(this.editedEntidad))
          : {}
        entidad.id_entidad =
          this.editedEntidad?.id_entidad || this.$uuid.createUUID()
        entidad.id_delegacion =
          this.editedEntidad?.id_delegacion || this.$uuid.createUUID()
        entidad.id_tipo_entidad = this.tipoEntidadSelected.id_tipo_entidad
        entidad.borrado = false
        entidad.entidad_titular = this.entidad_titular
        entidad.domicilio = this.domicilio
        entidad.cp = this.cp
        entidad.Tipo_entidad = this.tipoEntidadSelected
        entidad.Grupo_accions = this.grupoAccionSelected || []
        entidad.Distrito = this.distritoSelected || {}
        entidad.id_distrito = this.distritoSelected
          ? this.distritoSelected.id_distrito
          : null
        entidad.convenio = this.convenioSelected && this.convenioSelected.value
        entidad.Tipo_actualizacion = this.tipoActualizacionSelected || {}
        entidad.id_tipo_actualizacion =
          this.tipoActualizacionSelected &&
          this.tipoActualizacionSelected.id_tipo_actualizacion
        entidad.entidadGrupoAccion = this.montarEntidadGrupoAccion(entidad)
        entidad.Periodicidad = this.periodicidadSelected || {}
        entidad.id_periodicidad =
          this.periodicidadSelected && this.periodicidadSelected.id_periodicidad
        entidad.recogida_info = this.fechaInit && new Date(this.fechaInit)
        entidad.usuariostamp = this.currentUsuario.ID
        entidad.is_chat_default =
          this.isChatDefault === null ? false : this.isChatDefault
        entidad.is_chat_default_reunion_gimu =
          this.isChatDefaultReunionGimu === null
            ? false
            : this.isChatDefaultReunionGimu
        if (entidad.convenio && this.documentInput) {
          entidad.documentacion = await this.getDocumentacion()
        } else if (entidad.convenio && !this.documentInput) {
          entidad.documentacion = null
        } else if (!entidad.convenio) {
          this.documentInput = null
          entidad.documentacion = null
        }
        // Logo corporativo
        if (this.logoInput) {
          entidad.logo = await this.getLogo()
        } else {
          entidad.logo = null
          this.logoInput = null
        }
        if (this.editedEntidad) {
          this.$emit('editEntidad', entidad)
          this.showDocumentInput = false
          this.$refs.form.reset()
        } else {
          entidad.borrado = false
          entidad.id_departamento = this.$uuid.createUUID()
          // TODO: comprobar previamente si ya existe una entidad con ese nombre??
          this.$emit('addEntidad', entidad)
          this.showDocumentInput = false
          this.$refs.form.reset()
        }
      },

      async getDocumentacion() {
        if (!this.isEdit) {
          // Nueva documentacion para la nueva entidad
          const nombreDocumento = this.documentInput.name
          this.documentBase64 = await this.pdfToBase64(this.documentInput)
          if (this.documentBase64) {
            return {
              nombre: nombreDocumento,
              documento: this.documentBase64,
            }
          }
        } else {
          if (this.editedEntidad.documentacion === this.documentInput.name) {
            // El documento no se ha actualizado, convertir a base64 con el mismo nombre
            const nombreDocumento = this.documentInput.name
            this.documentBase64 = await this.pdfToBase64(this.documentInput)
            if (this.documentBase64) {
              return {
                nombre: nombreDocumento,
                documento: this.documentBase64,
              }
            }
          } else {
            // El documento se ha modificado respecto al original
            const actualDateTime = new Date().getTime()
            const nombreDocumento =
              this.documentInput.name.split('.pdf')[0] +
              '_' +
              actualDateTime +
              '.pdf'
            this.documentBase64 = await this.pdfToBase64(this.documentInput)
            if (this.documentBase64) {
              return {
                nombre: nombreDocumento,
                documento: this.documentBase64,
              }
            }
          }
        }
      },

      async getLogo() {
        if (!this.isEdit) {
          // Nuevo logo en formato imagen, para la delegacion
          const nombreImagen = this.logoInput.name
          this.logoBase64 = await this.imageToBase64(this.logoInput)
          if (this.logoBase64) {
            return {
              nombre: nombreImagen,
              logo: this.logoBase64,
            }
          }
        } else {
          if (this.editedEntidad.logo) {
            if (this.editedEntidad.logo.nombre === this.logoInput.name) {
              // La imagen no se ha actualizado, convertir a base64 con el mismo nombre
              const nombreImagen = this.logoInput.name
              this.logoBase64 = await this.imageToBase64(this.logoInput)
              if (this.logoBase64) {
                return {
                  nombre: nombreImagen,
                  logo: this.logoBase64,
                }
              }
            } else {
              // La imagen se ha actualizado respecto a la original
              const actualDateTime = new Date().getTime()
              const splitExtension = this.getImagenExtension(
                this.logoInput.name,
              )
              const nombreImagen =
                this.logoInput.name.split(splitExtension)[0] +
                '_' +
                actualDateTime +
                splitExtension
              this.logoBase64 = await this.imageToBase64(this.logoInput)
              if (this.logoBase64) {
                return {
                  nombre: nombreImagen,
                  logo: this.logoBase64,
                }
              }
            }
          } else {
            // Entidad editada no tiene logo previamente
            const actualDateTime = new Date().getTime()
            const splitExtension = this.getImagenExtension(this.logoInput.name)
            const nombreImagen =
              this.logoInput.name.split(splitExtension)[0] +
              '_' +
              actualDateTime +
              splitExtension
            this.logoBase64 = await this.imageToBase64(this.logoInput)
            if (this.logoBase64) {
              return {
                nombre: nombreImagen,
                logo: this.logoBase64,
              }
            }
          }
        }
      },

      async pdfToBase64(document) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(document)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })
      },

      async imageToBase64(image) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(image)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })
      },

      cerrarCalendario() {
        this.showFechaInit = false
      },

      tipoDeDocumentoAdmitido(documento) {
        const documentos = ['application/pdf']
        const permitido = documentos.find((type) => type === documento.type)

        return permitido
      },

      notificarDocumentoNoAdmitido() {
        this.$notify({
          title: 'Formato no permitido',
          text: 'Solo se admiten documentos pdf.',
          type: 'warning',
        })
      },

      tipoDeImagenAdmitida(imagen) {
        const imagenes = ['image/jpeg', 'image/png']
        const permitido = imagenes.find((type) => type === imagen.type)

        return permitido
      },

      notificarImagenNoAdmitida() {
        this.$notify({
          title: 'Formato no permitido',
          text: 'El formato de imagen no está permitido',
          type: 'warning',
        })
      },
    },
  }
</script>

<style scoped></style>
