import request from './request'
import auth from './auth'

import usuario from './usuario'
import rol from './rol'
import organizacion from './organizacion'
import contacto from './contacto'
import incendio from './incendio'
import medio from './medio'
import entidad from './entidad'
import distrito from './distrito'
import grupoAccion from './grupoAccion'
import periodicidad from './periodicidad'
import tipoActualizacion from './tipoActualizacion'
import tipoEntidad from './tipoEntidad'
import tipoMedio from './tipoMedio'
import delegacion from './delegacion'
import nivelContacto from './nivelContacto'
import unidadMedio from './unidadMedio'
import sectorMedio from './sectorMedio'
import actividadMedio from './actividadMedio'
import categoriaDocumento from './categoriaDocumento'
import documento from './documento'
import parametro from './parametro'
import simulacion from './simulacion'
import { EmergenciaRequest } from './emergencia'
import others from './others'
import { TipoRiesgoRequest } from './tipoRiesgo'
import { SubriesgoRequest } from './subRiesgo'
import { SituacionOperativaRequest } from './situacionOperativa'
import { TipoEmergenciaRequest } from './tipoEmergencia'
import tipoAccion from './tipoAccion'
import accionSituacionTipoEmergencia from './accionSituacionTipoEmergencia'
import accionSituacionEmergencia from './accionSituacionEmergencia'
import cargo from './cargo'
import tipoEventoBitacora from './tipoEventoBitacora'
import bitacora from './bitacora'
import documentoEmergenciasGimu from './documentoEmergenciasGimu'

export default {
  request,

  auth,

  usuario,
  rol,
  organizacion,
  contacto,
  incendio,
  medio,
  entidad,
  distrito,
  grupoAccion,
  periodicidad,
  tipoActualizacion,
  tipoEntidad,
  tipoMedio,
  delegacion,
  nivelContacto,
  unidadMedio,
  sectorMedio,
  actividadMedio,
  categoriaDocumento,
  documento,
  parametro,
  simulacion,
  EmergenciaRequest,
  TipoRiesgoRequest,
  SubriesgoRequest,
  SituacionOperativaRequest,
  TipoEmergenciaRequest,
  tipoAccion,
  accionSituacionTipoEmergencia,
  accionSituacionEmergencia,
  cargo,
  tipoEventoBitacora,
  bitacora,
  documentoEmergenciasGimu,

  others,
}
