<template>
  <v-container id="emergencias" :class="{ mobile: $vuetify.breakpoint.xs }">
    <div class="emergencias-header">
      <v-icon class="icon">mdi-alert-outline</v-icon>
      <span>Emergencias</span>
      <div class="options">
        <v-icon
          class="icon chevron"
          :class="{ down: !isExpanded }"
          @click="hideEmergencias"
        >
          mdi-chevron-up
        </v-icon>
        <v-icon class="icon" @click="toogleOptions()">mdi-menu</v-icon>
      </div>
    </div>
    <div
      @blur="toogleOptions(false)"
      :class="{ enabled: showOptions }"
      class="emergencias-options"
    >
      <div
        v-if="userCanEditEmergencias"
        class="option"
        @click="$emit('addEmergencia')"
      >
        <v-icon class="icon">mdi-alert-outline</v-icon>
        <span>Crear nueva emergencia</span>
      </div>

      <div class="option">
        <v-text-field
          type="text"
          label="Buscar emergencia"
          placeholder="Buscar emergencia"
          v-model="searchEmergencia"
          prepend-icon="mdi-magnify"
          dense
          hide-details
          @input="searchEmergency"
        />
      </div>

      <div class="option">
        <v-switch
          v-model="showInactive"
          label="Mostrar emergencias no activas"
          dense
          hide-details
        />
      </div>
    </div>
    <div :class="{ hide: !isExpanded }" class="emergencias-content">
      <div
        v-show="searchEmergencia == ''"
        v-for="emergencia in emergenciasAMostrar"
        :key="emergencia.id"
      >
        <vx-emergencia
          :emergencia="emergencia"
          @selectEmergencia="$emit('selectEmergencia', $event)"
        />
      </div>

      <div
        v-show="searchEmergencia != ''"
        v-for="emergencia in emergenciasFilter"
        :key="emergencia.id + emergencia.nombre"
      >
        <vx-emergencia
          :emergencia="emergencia"
          @selectEmergencia="$emit('selectEmergencia', $event)"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
  import { EmergenciaArcGISHelper } from '@/helpers/ArcGISHelpers/EmergenciaArcGISHelper'
  import { mapActions } from 'vuex'
  import EmergenciaVue from './Emergencia.vue'

  export default {
    components: {
      'vx-emergencia': EmergenciaVue,
    },

    props: {
      emergencias: {
        type: Array,
        default: () => [],
      },
    },

    data: () => ({
      show: false,
      showOptions: false,
      isExpanded: true,
      searchEmergencia: '',
      emergenciasFilter: null,
    }),

    computed: {
      showInactive: {
        get() {
          return this.$store.state.emergencia.showInactive
        },
        async set(value) {
          console.log('set showInactive', value)
          await this.$store.commit('emergencia/setShowInactive', value)
          if (
            (this.searchEmergencia !== '' && value) ||
            (this.searchEmergencia !== '' && !value)
          ) {
            this.searchEmergency()
          }

          // Realizar un filtro de prueba sobre la capa de emergencias
          this.filtrarEmergencias(value)
        },
      },
      emergenciasAMostrar() {
        if (this.emergencias) {
          return this.showInactive
            ? this.orderEmergencias().filter((emergencia) => !emergencia.activa)
            : this.orderEmergencias().filter((emergencia) => emergencia.activa)
        }
        return null
      },

      userCanEditEmergencias() {
        return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
      },
    },

    methods: {
      toogleOptions(state) {
        if (state) {
          this.showOptions = state
        } else {
          this.showOptions = !this.showOptions
        }
      },

      hideEmergencias() {
        this.isExpanded = !this.isExpanded
      },

      ocultarIncendio(incendio) {
        this.$store.dispatch('incendio/ocultarIncendio', {
          ID_INCENDIO: incendio.ID_INCENDIO,
          OCULTO: true,
        })
      },

      orderEmergencias() {
        if (this.emergencias) {
          const orderEmergencias = [...this.emergencias]
          orderEmergencias.sort((a, b) => {
            const aEstado = a.SituacionOperativa?.prioridad
            const bEstado = b.SituacionOperativa?.prioridad
            const aFecha = a.fechaCreacion
            const bFecha = b.fechaCreacion

            if (a.activa === b.activa) {
              if (aEstado && bEstado) {
                if (aEstado === bEstado) {
                  return aFecha > bFecha ? -1 : aFecha < bFecha ? 1 : 0
                } else {
                  return aEstado < bEstado ? 1 : -1
                }
              } else {
                return a.Estado ? -1 : 1
              }
            } else {
              return a.activa ? -1 : 1
            }
          })
          return orderEmergencias
        }
      },

      searchEmergency() {
        // Atributos no validos para filtrar
        function atributoValido(atributo) {
          const atributosNoValidos = [
            'codigo',
            'activa',
            'borrado',
            'latitude',
            'longitude',
            'tiene_hora',
            'descripcion',
            'TipoRiesgos',
            'fechastamp',
            'fechaActivacion',
            'fecha_declaracion',
            'codigo',
            'prioridad',
            'FaseEmergencium',
            'emergencias',
            'asignadaAreunionGimu',
          ]

          const encontrado = atributosNoValidos.find((x) => x === atributo)
          return encontrado ? false : true
        }

        // Filtro de búsqueda en todas las emergencias
        let searchText = this.searchEmergencia.toLowerCase()
        // Normalizar texto de búsqueda del usuario
        searchText = searchText.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

        this.emergenciasFilter = this.emergenciasAMostrar
          .filter(function (emergencias) {
            for (var attrEmergencia in emergencias) {
              if (atributoValido(attrEmergencia)) {
                // Tratar la situacion operativa
                if (attrEmergencia === 'SituacionOperativa') {
                  for (var attrSitOpe in emergencias[attrEmergencia]) {
                    if (atributoValido(attrSitOpe)) {
                      // let atributoNormalizado = normalizeData(emergencias[attrEmergencia][attrSitOpe]) // TODO: no normaliza

                      const atributo = emergencias[attrEmergencia][attrSitOpe]
                      const atributoNormalizado = atributo
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')

                      if (
                        atributoNormalizado
                          .toLowerCase()
                          .indexOf(searchText.toLowerCase()) > -1
                      )
                        return emergencias[attrEmergencia]
                    }
                  }
                } else {
                  // Si hay campos de la emergencia undefined
                  if (emergencias[attrEmergencia]) {
                    // let atributoNormalizado = normalizeData(emergencias[attrEmergencia].toLowerCase()) // TODO: no normaliza

                    const atributo = emergencias[attrEmergencia]
                    const atributoNormalizado = atributo
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')

                    if (
                      atributoNormalizado
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) > -1
                    )
                      return emergencias
                  }
                }
              }
            }
          })
          .filter((x) => x.activa === !this.showInactive)
      },

      async filtrarEmergencias(showInactive) {
        /**
         * Filtrar capa de emergencias por activas/inactivas
         * - Por defecto se muestran las emergencias activas (showInactive - false)
         */
        EmergenciaArcGISHelper.filterLayerEmergencias(showInactive)
      },

      ...mapActions('tipoEventoBitacora', ['fetchTiposEventoBitacora']),
      ...mapActions('emergencia', ['fetchEmergencias']),

      async loadTiposEventoBitacora() {
        try {
          await this.fetchTiposEventoBitacora()
        } catch (error) {
          console.error(
            'Error al cargar los tipos de evento de bitácora',
            error,
          )
        }
      },
    },

    mounted() {
      this.orderEmergencias()
      this.filtrarEmergencias(false)
      this.$eventHub.$emit('updateLayerList', true)
      // Cargar tiposEventoBitacora
      this.loadTiposEventoBitacora()
    },

    beforeDestroy() {
      this.filtrarEmergencias(false)
      this.$eventHub.$emit('updateLayerList', true)
    },
  }
</script>

<style lang="scss" scoped>
  #emergencias {
    align-content: flex-start;
    position: absolute;
    top: 20px;
    left: 15px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    width: 300px;
    max-height: calc(100% - 100px);
    &.mobile {
      z-index: 2;
      width: 100%;
      left: 0px;
      top: 0px;
      max-height: inherit;
      height: auto;
      padding: 0;
    }
    .emergencias-header {
      padding: 5px 8px;
      flex-basis: 50px;
      flex-shrink: 0;
      background: #272727;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px 4px 0px 0px;
      span {
        padding-left: 13px;
        width: 100%;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
      }
      .icon {
        color: #ffffff;
      }
      .options {
        display: flex;
        .icon {
          color: #ffffff;
          &:first-child {
            margin-right: 8px;
          }
          &.chevron {
            transition: transform 0.2s ease-in-out;
            &.down {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .emergencias-options {
      overflow: hidden;
      flex-shrink: 0;
      align-content: flex-start;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
      max-height: 0px;
      transition: max-height 0.25s;
      cursor: pointer;
      &.enabled {
        max-height: 160px;
      }

      .option {
        align-items: center;
        display: flex;
        padding: 5px 0;
        gap: 4px;
        margin: 0 10px;
        &:not(:first-child) {
          border-top-color: #d9d9d9;
          border-top-width: 1px;
          border-top-style: solid;
        }
        .v-input--selection-controls {
          margin: 0px;
          padding: 0px;
          .v-messages {
            display: none;
          }
        }

        .icon {
          padding-right: 17px;
          color: #747474;
        }
        span {
          font-weight: 300;
          font-family: 'Lato';
          font-style: normal;
          font-size: 16px;
          line-height: 19px;
          color: #747474;
        }
      }
    }
    .emergencias-content {
      height: 100%;
      overflow-y: auto;
      background: rgba(1, 1, 1, 0.15);
      max-height: 500px;
      transition: max-height 0.25s;
      &.hide {
        max-height: 0px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::v-deep .v-text-field.v-input--dense {
    padding-top: 6px;
  }
</style>
